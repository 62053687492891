import {
  PERFORM_LOGIN,
} from './action-types';

import { UserWhoami } from '../api/types'

export * from './toast'


export interface performLoginAction {
  type: typeof PERFORM_LOGIN,
  user: UserWhoami
}

export function set_user_data(user: UserWhoami): performLoginAction {
  return { type: PERFORM_LOGIN, user: user };
}
