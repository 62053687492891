import React, { useEffect, useState } from 'react'
import { RESEARCHER_STATUS } from '../globals'
import { chunk } from 'django-rest-react'
import { PersonCircle } from '../components';
import { get_external_website, get_researcher } from '../api';



const People = () => {
  const horiz = (window.innerWidth > window.innerHeight);
  const divisione = horiz ? 3 : 1;

  const [researchers, setResearchers] = useState([]);
  const [websites, setWebsites] = useState([]);

  useEffect(() => {
    get_researcher().then(setResearchers)
    get_external_website({}).then(setWebsites)
  }, [])


  return (
    <div className="container">
      <div className="simil-header">
        Team members
      </div>

      <div className="container">
        {RESEARCHER_STATUS.map((cat, idx) =>
          <React.Fragment key={idx}>
            <ul style={{ paddingInlineStart: '0px' }}>
              {chunk(researchers.filter(r => r.status == cat.id).filter(r => r.currently_member), divisione).map((item, idx1) =>
                <div className="flex-row" key={idx1}>
                  {item.map((r, idx2) => <PersonCircle key={r.id} researcher={r} links={websites.filter(link => link.researcher == r.id)} />)}
                </div>
              )}
            </ul>
            {idx != (RESEARCHER_STATUS.length - 1) &&
              <hr className="my-3" />
            }
          </React.Fragment>
        )}

        <h3 className="text-center">Former members</h3>
        <ul style={{ paddingInlineStart: '0px' }}>
          {chunk(researchers.filter(r => !r.currently_member), divisione).map((item, idx1) =>
            <div className="flex-row" key={idx1}>
              {item.map((r, idx2) => <PersonCircle key={r.id} researcher={r} links={websites.filter(link => link.researcher == r.id)} />)}
            </div>
          )}
        </ul>

      </div>
    </div>
  )
}


export default People
