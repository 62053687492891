import * as T from './auto-types';
import {
   get_paginated_json, post_page, put_page, patch_page, delete_page,
   get_single_json, plain_object,
} from 'django-rest-react';

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;


interface FilterExternalWebsite {
researcher?: number,
}
export const get_external_website = async (filter: FilterExternalWebsite) => get_paginated_json<T.ExternalWebsite & { id: number }>(`/api/portfolio/external-website/`, filter as unknown as plain_object)
export const get_external_website_detail = async (pk: number) => get_single_json<T.ExternalWebsite & { id: number }>(`/api/portfolio/external-website/${pk}/`)
export const get_news = async () => get_paginated_json<T.News & { id: number }>(`/api/portfolio/news/`)
export const get_news_detail = async (pk: number) => get_single_json<T.News & { id: number }>(`/api/portfolio/news/${pk}/`)
interface FilterPaper {
internal_authors?: number,
}
export const get_paper = async (filter: FilterPaper) => get_paginated_json<T.Paper & { id: number }>(`/api/portfolio/paper/`, filter as unknown as plain_object)
export const get_paper_detail = async (pk: number) => get_single_json<T.Paper & { id: number }>(`/api/portfolio/paper/${pk}/`)
export const get_researcher = async () => get_paginated_json<T.Researcher & { id: number }>(`/api/portfolio/researcher/`)
export const get_researcher_detail = async (pk: number) => get_single_json<T.Researcher & { id: number }>(`/api/portfolio/researcher/${pk}/`)
export const get_whoami = async () => get_single_json<T.Whoami & { id: number }>(`/api/whoami/`)