import React, { Component, CSSProperties } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { plain_object } from 'django-rest-react';
import { sentry_log } from '../utils'


const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
}

interface IProps {
  load(): Promise<plain_object>
  children: React.ReactElement<any>
}

interface IState {
  loading: boolean,
  error: boolean,
  error_details: plain_object,
  extra: plain_object
}

export default class Loadable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      error_details: null,
      extra: null
    };
    this.carica = this.carica.bind(this);
  }

  carica() {
    this.props.load().then((dict: any) => {
      this.setState({
        loading: false,
        extra: dict
      });
    }).catch((error: any) => {
      sentry_log(error);
      this.setState({
        error: true,
        error_details: error
      });
    });
  }

  componentDidMount() {
    this.carica();
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h4>Errore nel caricamento: {JSON.stringify(this.state.error_details.message)}</h4>
          <button onClick={this.carica} className="btn btn-primary">Riprova</button>
        </div>
      );
    }
    if (this.state.loading) {
      return (
        <ClipLoader
          cssOverride={override}
          color={'#42f456'}
          loading={true}
        />
      );
    } else {
      let child = React.cloneElement(
        this.props.children,
        this.state.extra
      );
      return (
        <>
          {child}
        </>
      );
    }
  }
}
