import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get_external_website, get_paper, get_researcher } from '../api'
import { LoadingCircle, MdContent, PersonCircle } from '../components'
import { PaperView } from './Publications'


const ResearcherPage = () => {
  const params = useParams<{ pk: string }>()

  const [researchers, setResearchers] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [papers, setPapers] = useState([]);

  useEffect(() => {
    get_researcher().then(setResearchers)
    get_external_website({ researcher: parseInt(params.pk) }).then(setWebsites)
    get_paper({ internal_authors: parseInt(params.pk) }).then(setPapers)
  }, [])


  const researcher = researchers.find(r => r.id === parseInt(params.pk))
  if (!researcher) {
    return <LoadingCircle />
  }


  return (
    <div className="container">
      <PersonCircle researcher={researcher} links={websites} />
      {researcher.research_interests &&
        <>
          <h2>Research interests</h2>
          <MdContent source={researcher.research_interests} />
        </>
      }
      <h2>Publications</h2>
      {papers.map(paper => <PaperView paper={paper} researchers={researchers} start_open={false} />)}
    </div>
  )
}


export default ResearcherPage
