import React, { useEffect, useState } from 'react'
import { MdContent } from '../components';
import { nice_date } from 'django-rest-react';
import { get_paper, get_researcher } from '../api';
import { Paper, Researcher } from '../api/types'
import { range } from 'lodash';
import { ARXIV_REGEX } from '../globals';


export const PaperView = (props: { paper: Paper, researchers: Researcher[], start_open: boolean }) => {
  const { paper, researchers, start_open } = props;
  const [open, setOpen] = useState(start_open);

  const idx = parseInt(paper.arxiv_id.match(ARXIV_REGEX)[2])
  const resub = idx > 1;

  const icon = "book-open";
  const title = !!paper.arxiv_id ? <a href={paper.arxiv_id}>{paper.title}</a> : paper.title;
  const extra = <p>Group members involved: {paper.internal_authors.map(rpk => researchers.find(r => r.id === rpk)).map(res => `${res?.name} ${res?.surname}`).join(", ")}
  </p>
  const publication = !!paper.journal ? paper.journal : "arXiv Pre-print";
  return (
    <div className="card my-2" key={paper.id}>
      <div>
        <div className="card-header" onClick={() => setOpen(v => !v)}>
          <div className="d-flex justify-content-between">
            <span><h4><i className={`fa fa-${icon} mr-3`} />{resub && `Resub v${idx} - `}{title}</h4></span>
            <span>{nice_date(paper.date)}</span>
          </div>
          <div className="d-flex justify-content-between">
            <h5>{extra}</h5>
            <h6>{publication}</h6>
          </div>
        </div>
      </div>
      {open &&
        <div className="card-body">
          <MdContent source={paper.abstract} />
        </div>
      }
    </div>
  )

}

const Publications = () => {
  const [papers, setPapers] = useState([])
  const [researchers, setResearchers] = useState([]);


  useEffect(() => {
    get_paper({}).then(setPapers)
    get_researcher().then(setResearchers)
  }, [])

  const show_paper = (paper: Paper) => <PaperView key={paper.id} paper={paper} researchers={researchers} start_open />;
  const cur_year = (new Date()).getFullYear()
  const years = range(2020, cur_year)


  const filterFactory = (year: number) => (paper: Paper) => {
    if (new Date(paper.date).getFullYear() != year) return false;
    const [_, arxiv_id, revision] = paper.arxiv_id.match(ARXIV_REGEX);
    return (!revision || parseInt(revision) === 1)

  }

  return (
    <div className="container mt-2">
      <h1>Selected publications</h1>
      {papers.filter(paper => paper.selected).map(show_paper)}
      <h1>Latest publications (this year)</h1>
      {papers.filter(filterFactory(cur_year)).map(show_paper)}
      {years.reverse().map(year => {
        return (
          <React.Fragment key={year}>
            <h1>Publications of {year}</h1>
            {papers.filter(filterFactory(year)).map(show_paper)}
          </React.Fragment>
        )
      })}

    </div>
  )
}


export default Publications
