import { combineReducers } from 'redux';

import {
  loadingReducer, LoadingState,
  tentativoReducer, TentativoState
} from '../django-rest-react/reducers';


import { reducers_veri } from './cache'
import { loginReducer, LoginState } from './login'
import { toastReducer, ToastState } from './toast'
import { useSelector } from 'react-redux';

const rootReducer = combineReducers({
  login: loginReducer,
  tentativi: tentativoReducer,
  loading: loadingReducer,
  toasts: toastReducer,
  ...reducers_veri
});



// Tutto questo non dovrebbe essere necessario ma typescript ha la mamma puttana
export interface AppState {
  login: LoginState,
  tentativi: TentativoState
  toasts: ToastState,
  loading: LoadingState
}
/* export type AppState = ReturnType<typeof rootReducer>; */

export const useLogin = () => useSelector((state: AppState) => state.login);

export default rootReducer;
export * from './cache';
