
import { WithId } from '../django-rest-react/low_level'
import { Esportone, StructuredEsportone } from '../django-rest-react/redux-auto-solver'



type AppState = any;


interface ReverseMap {
  [key: string]: Esportone<WithId> | StructuredEsportone<WithId>
}

export const reducers_map: ReverseMap = {
}

let reducers_veri: ReverseMap = {};
Object.keys(reducers_map).forEach(key => {
  reducers_veri[key] = reducers_map[key].objectReducer.bind(reducers_map[key]);
});


export { reducers_veri };
