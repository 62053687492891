import React, { useEffect, useState } from 'react'

import { get_seminar } from '../api'
import { nice_date } from '../utils'


type Seminar = Awaited<ReturnType<typeof get_seminar>>["items"][0];

const SeminarView = (props: { seminar: Seminar }) => {

  const { seminar } = props;

  function createMarkup() {
    return { __html: seminar.description };
  }

  function MyComponent() {
    return <div dangerouslySetInnerHTML={createMarkup()} />;
  }

  return (
    <div className="card my-1" key={seminar.id}>
      <div className="card-header d-flex justify-content-between">
        <a href={seminar.htmlLink}><h4>{seminar.summary}</h4></a>
        <div>{seminar.start && nice_date(seminar.start.dateTime)}</div>
      </div>
      <div className="card-body">
        {seminar.location && <span>Location: {seminar.location}</span>}
        <MyComponent />
      </div>
    </div>
  )

}

const sortSeminar = (s1: Seminar, s2: Seminar) => {
  const start1 = new Date(s1.start.dateTime);
  const start2 = new Date(s2.start.dateTime);
  return start1.getTime() - start2.getTime();
}

const Seminars = () => {
  const [seminars, setSeminars] = useState([] as Seminar[]);

  useEffect(() => {
    get_seminar().then(seminars => setSeminars(seminars.items.sort(sortSeminar)))
  }, [])

  const compare_function = (seminar: Seminar) => {
    const start = new Date(seminar.start.dateTime)
    const now = new Date();
    return (start.getTime() - now.getTime()) > -86400 * 1000
  }

  const next_seminars = seminars.filter(compare_function);
  const past_seminars = seminars.filter(s => !compare_function(s))

  return (
    <div id="seminars" className="py-4">
      <h1 className="simil-header">Next seminars</h1>

      <div className="container">
        <p>You can subscribe to the calendar events following this <a href="https://calendar.google.com/calendar/embed?src=c_61a4c3f4a2c90b391e993ea739a2d44df0c9362b6a3f3ffbde5fe2ec880901c1%40group.calendar.google.com&ctz=Europe%2FRome">Google Calendar link</a> </p>
        {next_seminars.map(seminar => <SeminarView key={seminar.etag} seminar={seminar} />)}

        <h1 className="simil-header">Past seminars</h1>
        {past_seminars.map(seminar => <SeminarView key={seminar.etag} seminar={seminar} />)}

      </div>
    </div>
  )
}


export default Seminars;
