import "core-js/stable";
import "regenerator-runtime/runtime";
import { Displayable, DEBUG } from '../globals';

/**
 * Prende una ministringa che è l'id di un displayable
 * e restituisce il nome guardabile
 */
export function displayDisplayable(obj: string, family: Displayable[]): string {
  const match = family.find((item) => item.id === obj);
  if (match) {
    return match.display;
  } else {
    console.warn("Non matchato il seguente: " + obj);
    return "";
  }
}

export function removeUnderscore(string: string): string {
  return string.replace('_', ' ');
}


export function formNameToNice(string: string): string {
  const val = removeUnderscore(string);
  return val.charAt(0).toUpperCase() + val.slice(1);
}


/**
 * Questa funzione fa zero padding dei numeri.
 * pad(13, 4) = 0013
 * pad(13, 4, 2) = 2213
 */
export function pad(n: string, width: number, z?: string): string {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}


export const ws_base_url: string = (DEBUG ? 'ws' : 'wss') + "://" + window.location.host;


export function chunk<T>(arr: T[], len: number) {
  let chunks = [], i = 0, n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }
  return chunks;
}
