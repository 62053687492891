import React, { Component } from 'react';
import { css } from '@emotion/core';

// @ts-ignore
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


export default class LoadingCircle extends Component {
  render() {
    return <ClipLoader
      css={override}
      color={'#42f456'}
      loading={true}
    />
  }
}
