import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';


interface ownProps {
  source: string
}

const MdMath = (props: ownProps) => {
  const mdmath = props.source;

  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath]} rehypePlugins={[rehypeMathjax]}
    >
      {mdmath}
    </ReactMarkdown>
  )
}

export default MdMath;
