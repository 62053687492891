import { PERFORM_LOGIN } from '../actions/action-types'
import { UserWhoami } from '../api/types';
import { performLoginAction } from '../actions';

export interface LoginState {
  logged_in: boolean,
  user?: UserWhoami
}

export function loginReducer(state: LoginState = { logged_in: false, user: undefined },
  action: performLoginAction) {
  if (action.type == PERFORM_LOGIN) {
    return Object.assign({}, state, {
      logged_in: true,
      user: action.user
    });
  } else {
    return state;
  }
}
