import React from 'react';
import { Link } from "react-router-dom";

import { useLogin } from '../reducers';


const Navbar = () => {
  const login = useLogin();
  let login_block;
  if (login.logged_in) {
    let superuser;
    if (login.user.is_staff) {
      superuser = <a className="dropdown-item" href="/admin/">Django admin</a>
    }
    login_block = (
      <>
        {superuser}
        <a className="dropdown-item" href="/accounts/logout/">Logout</a>
      </>
    );
  } else {
    login_block = (
      <>
        <a className="dropdown-item" href="/accounts/login/">Login page</a>
      </>
    );
  }
  const data = login.user
  let log_name;
  if (login.logged_in) {
    if (data.first_name != "" || data.last_name != "") {
      log_name = data.first_name + " " + data.last_name;
    } else {
      log_name = data.username
    }
  } else {
    log_name = "Login";
  }
  return (
    <nav className="navbar navbar-nav navbar-dark bg-primary navbar-expand-lg px-3">
      <div className="container">
        <Link to={`/`} className="navbar-brand py-0">
          Home
        </Link>
        <button
          className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={`/seminars/`} className="nav-link">Seminars</Link>
            </li>
            <li className="nav-item">
              <Link to={`/people/`} className="nav-link">People</Link>
            </li>
            <li className="nav-item">
              <Link to={`/publications/`} className="nav-link">Publications</Link>
            </li>
            <li className="nav-item">
              <Link to={`/work-with-us/`} className="nav-link">Work with us</Link>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown ml-auto">
              <a className="nav-link dropdown-toggle" href="#"
                id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {log_name}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                {login_block}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
