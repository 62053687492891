import React from 'react';
import * as Sentry from '@sentry/browser';
import { Provider } from "react-redux";
import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements, Outlet } from "react-router-dom";
import { createRoot } from 'react-dom/client';

import { sentry_config } from '../globals';
import store from '../store';
import { get_whoami } from '../api';

import { set_user_data, add_toast_action, delete_old_toasts } from '../actions';
import { plain_object } from '../django-rest-react';
import { Courses, Home, NoMatch, People, Publications, ResearcherPage } from '../screens'
import { Navbar, Seminars } from '../components'
import { Loadable, LoadingBar, ToastFeed, APIErrorCatcher } from '../common-components'
import { TESTING, DEBUG } from '../globals'
import { configure_drr } from 'django-rest-react';
import { reducers_map } from '../reducers'


const LayoutFactory = (elem: React.ReactNode, effects: boolean) => () => {

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ minHeight: '100%', height: '100%' }}>
      <div>
        <LoadingBar />
        <Navbar />
        <div style={{ position: 'relative', zIndex: 3 }}>
          <div style={{ position: 'relative', zIndex: 1000 }}>
            <ToastFeed />
          </div>
          <div style={{ position: 'relative', zIndex: 1 }}>
            {elem}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

const Layout = LayoutFactory(<Outlet />, false)
const ErrorElement = LayoutFactory(<APIErrorCatcher get_username={s => s.user.username} />, false)

export const ROUTES = (
  <Route path="/*" element={<Layout />} errorElement={<ErrorElement />}>
    <Route index path="" element={<Home />} />
    <Route path="seminars/" element={<Seminars />} />
    <Route path="people/" element={<People />} />
    <Route path="work-with-us/" element={<Courses />} />
    <Route path="publications/" element={<Publications />} />
    <Route path="researcher/:pk/" element={<ResearcherPage />} />
    <Route path="*" element={<NoMatch />} />
  </Route>
)

export const AppFactory = (creator: typeof createBrowserRouter, store_: typeof store) => () => {
  const router = creator(createRoutesFromElements(ROUTES))
  return (
    <Loadable load={carica}>
      <Provider store={store_}>
        <RouterProvider router={router} />
      </Provider>
    </Loadable>
  )
}
const App = AppFactory(createBrowserRouter, store);
export default App;



const wrapper = document.getElementById("app");
const cusu = document.getElementById("user");
const username = cusu ? cusu.textContent : "__not_logged_in";
const carica: () => Promise<plain_object> =
  function() {
    const get_connection_toast = (val: string) => {
      return {
        title: "Connessione persa",
        message: "Tentativo di riconnessione in corso",
        generating_object: val,

      }
    }

    configure_drr(
      reducers_map, store, DEBUG,
      val => delete_old_toasts(get_connection_toast(val)),
      val => add_toast_action(get_connection_toast(val)),
    );


    if (username != "__not_logged_in") {
      return get_whoami().then((io) => {
        store.dispatch(set_user_data(io));
        return {};
      });
    } else {
      return new Promise((resolve) => {
        resolve({});
      });
    }
  }


if (sentry_config.use_sentry) {
  Sentry.init({
    dsn: sentry_config.dsn,
    release: sentry_config.release,
  });
}


if (!TESTING) {
  createRoot(wrapper).render(<App />)
}

const mapRoutesToArray = (routes: typeof ROUTES) => {
  const paths = [] as string[];
  const children = routes.props.children;
  children.map((route: typeof ROUTES.props.children[0]) => {
    const inner = route.props.path;
    if (!inner) return
    if (Array.isArray(inner)) {
      paths.concat(inner)
    } else {
      paths.push(inner as string)
    }
  })
  return paths;
}

export const generate_sitemap = () => mapRoutesToArray(ROUTES)
