
import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { image_placeholder_url } from '../globals'
import { Researcher, ExternalWebsite } from '../api/types'
import { displayStatus } from '../globals'
import { displayResearcher } from '../utils';


interface IProps {
  researcher: Researcher
  links: ExternalWebsite[]
}


export const foto_rotonda_style = (size: number) => {
  return {
    margin: '0 auto',
    marginBottom: '0.75em',
    width: `${size}vw`,
    height: `${size}vw`,
    borderRadius: `${size / 2}vw`,
  }
}

const brand_icon = (kind: ExternalWebsite["kind"]) => {
  switch (kind) {
    case 'fb': return "fab fa-facebook";
    case 'tw': return "fab fa-twitter";
    case 'gs': return "fab fa-google";
    case 'oi': return "fab fa-orcid";
    case 'li': return "fab fa-linkedin";
    case 'other': return "fas fa-globe";
  }
}


export default class PersonCircle extends Component<IProps> {

  render() {
    const horiz = (window.innerWidth > window.innerHeight);
    const { researcher } = this.props;
    const size = horiz ? 10 : 60;
    let { website, picture, status, verbose_affiliation, email } = researcher;
    const real_image_src = picture ? picture as unknown as string : image_placeholder_url;
    const name = displayResearcher(researcher)
    const rstatus = verbose_affiliation ? verbose_affiliation : displayStatus(status);
    return (
      <div className="bannerino flex-grow-1">
        <Link to={`/researcher/${researcher.id}/`} className="picture-researcher" style={foto_rotonda_style(size)}>
          <img
            src={real_image_src}
            className="img-thumbnail"
            alt={name}
            style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: `${size / 2}vw` }}
          />
        </Link>
        <h4 className="text-row text-center">
          <a href={website}>
            {name}
          </a>
        </h4>
        <span>
          {this.props.links.map(link => <a key={link.kind} href={link.link}><i className={brand_icon(link.kind)} /> </a>)}
        </span>
        <p><a href={`mailto:${email}`}>{email}</a></p>
        <p className="text-baby-blue">{rstatus}</p>
      </div>

    )
  }
}
