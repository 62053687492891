import { WithId } from '../django-rest-react/low_level'
import { ResearcherStatus, NewsKind } from '../api/types'
import { sentry_log } from '../utils'

export interface Displayable extends WithId {
  display: string
}



export const RESEARCHER_STATUS = [
  { id: 'prof', display: "Professor" },
  { id: 'pd', display: "PostDoc" },
  { id: 'phd', display: "PhD student" },
  { id: 'm', display: "Master Student" },
]

/**
 * Prende una ministringa che è l'id di un displayable
 * e restituisce il nome guardabile
 */
export function displayDisplayable(obj: string, family: Displayable[]): string {
  const match = family.find((item) => item.id === obj);
  if (match) {
    return match.display;
  } else {
    console.warn("Non matchato il seguente: " + obj);
    return "";
  }
}




export const displayStatus = (s: ResearcherStatus) => displayDisplayable(s, RESEARCHER_STATUS)



export const news_kind_to_icon = (n: NewsKind) => {
  switch (n) {
    case "ppp": return "book-open";
    case "qv": return "book-reader";
    case "o": return "book-cusumano";
    default: sentry_log(new Error(`Unexpected news kind: ${n}`)); return "";

  }
}
